import React from 'react';
import {occasion, name, backgroundSquare, button, imageWrapper, image, imageMain, imageHover} from './label-occasion.module.scss'
import {ILabelOccasion} from "../../models/label-occasion.model";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {Link} from 'gatsby';

const LabelOccasion: React.FC<ILabelOccasion> = ({title, hoverPhoto, mainPhoto}) => {

    const { t } = useI18next();
    const mainImage = getImage(mainPhoto[0].localFile);
    const hoverImage = getImage(hoverPhoto[0].localFile);

    let slug = '';

    switch (title) {
        case 'Wesele':
            slug = '/soplica-weselna';
            break;
        case 'Urodziny':
            slug = '/soplica-urodzinowa';
            break;
        case 'Rocznica Ślubu':
            slug = '/soplica-rocznicowa';
            break;
    }

    return (
        <Link to={`${slug}`} className={occasion}>
            <div className={imageWrapper}>
                {mainImage && <GatsbyImage className={`${image} ${imageMain}`} alt={''} image={mainImage} />}
                {hoverImage && <GatsbyImage className={`${image} ${imageHover}`} alt={''} image={hoverImage} loading={"eager"}/>}
                <div className={backgroundSquare}></div>
            </div>
            <p className={name}>{title}</p>
            <button className={button}>{t('label.occasions.button')}</button>
        </Link>
    )
}

export default LabelOccasion