// extracted by mini-css-extract-plugin
export var backgroundAnimation = "label-occasion-module--backgroundAnimation--e55f0";
export var backgroundSquare = "label-occasion-module--backgroundSquare--d9f34";
export var button = "label-occasion-module--button--0df0b";
export var errorBlink = "label-occasion-module--error-blink--8600d";
export var image = "label-occasion-module--image--effbb";
export var imageHover = "label-occasion-module--image-hover--222e2";
export var imageMain = "label-occasion-module--image-main--4b1f9";
export var imageWrapper = "label-occasion-module--imageWrapper--71e93";
export var name = "label-occasion-module--name--255fa";
export var occasion = "label-occasion-module--occasion--df7f6";